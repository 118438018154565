import React from 'react';
import styles from './styles.module.css';

import { Layout } from 'src/components/Layout';
import { ProgressBar } from 'src/components/ProgressBar';
import { Spinner } from 'src/components/Spinner';

import { Questions } from './components/Questions';
import { GenericTestQuestionSpec } from 'src/components/GenericPuzzle';
import { PuzzleComplete } from 'src/components/PuzzleComplete';

import { PuzzleSessionManager } from 'src/helpers/PuzzleSessionManager/';
import {
	progressBarQuestionsFromSessionQuestions,
	getNextQuestion,
} from 'src/helpers/PuzzleSessionManager/QuestionUtilities';

import { LocationTestProps } from 'src/model/LocationTestProps.interface';
import { TestState } from 'src/model/TestState.interface';
import { signInAsGuestUserIfRequired } from 'src/helpers/UserManager';
import { isSignUpRequired } from 'src/helpers/SignUpThresholdHelper';
import { navigate } from 'gatsby';

export default class GenericTest extends React.Component<LocationTestProps, TestState> {
	state: TestState = {
		currentQuestionNum: 0,
		questions: [],
		isLoaded: false,
		sessionQuestionId: undefined,
		sessionId: undefined,
		lastSessionId: undefined,
		puzzleIsComplete: false,
		error: false,
		currentQuestionSpec: null,
		acquiredSkills: [],
	};

	async componentDidMount() {
		const puzzle = this.props.pageContext.puzzleData;
		await signInAsGuestUserIfRequired();
		const showCreateAccount: boolean = await isSignUpRequired();
		if (showCreateAccount) {
			navigate('/sign-up-required');
		} else {
			// TODO Is sessionId ever set?  This approach is used across all puzzle pages.
			let { lastSessionId } = this.state;
			const response = await getNextQuestion(puzzle.id, this.state.sessionId);
			if (response.sessionId) lastSessionId = response.sessionId;
			this.setState({ lastSessionId });
			this.setState(response);
		}
	}

	render() {
		const {
			isLoaded,
			questions,
			puzzleIsComplete,
			currentQuestionNum,
			currentQuestionSpec,
			error,
			sessionQuestionId,
			acquiredSkills,
		} = this.state;

		const progressBarQuestions = progressBarQuestionsFromSessionQuestions(
			questions,
			currentQuestionNum
		);

		const gtQuestionSpec = currentQuestionSpec as GenericTestQuestionSpec;

		return (
			<Layout>
				<div className={styles.container}>
					<h1>{this.props.pageContext.puzzleData.title}</h1>

					{error ? (
						<div>An error has occurred</div>
					) : (
						<>
							{isLoaded && questions ? (
								<>
									<div className={styles.subContainer}>
										{!puzzleIsComplete ? (
											<>
												<ProgressBar questions={progressBarQuestions} />
												<Questions
													currQuestionNum={currentQuestionNum}
													currQuestionSpec={gtQuestionSpec}
													questionTotal={questions.length}
													nextQuestion={async (answerValue?: string) => {
														// call to API
														if (sessionQuestionId) {
															await PuzzleSessionManager.answerQuestion(
																sessionQuestionId,
																answerValue
															);

															// TODO Is sessionId ever set?  This approach is used across all puzzle pages.
															const response = await getNextQuestion(
																this.props.pageContext.puzzleData.id,
																this.state.sessionId
															);
															this.setState(response);
														} else {
															console.error(`No sessionQuestionId`);
														}
													}}
												/>
											</>
										) : (
											<PuzzleComplete
												skills={acquiredSkills}
												puzzleSessionId={this.state.lastSessionId ? this.state.lastSessionId : 0}
											/>
										)}
									</div>
								</>
							) : (
								<Spinner caption="Loading Puzzle" />
							)}
						</>
					)}
				</div>
			</Layout>
		);
	}
}
