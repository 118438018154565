import React from 'react';

import { GenericPuzzle, GenericTestQuestionSpec } from 'src/components/GenericPuzzle';
import { SettingsManager } from 'src/helpers/SettingsManager';
import { UserManager } from 'src/helpers/UserManager';

interface Props {
	currQuestionNum: number;
	currQuestionSpec: GenericTestQuestionSpec;
	questionTotal: number;
	nextQuestion: (answer?: string | undefined) => void;
}

interface State {
	layoutData: string[][];
	answers: Array<string>;
	genericPuzzle: GenericPuzzle;
}

export class Questions extends React.Component<Props> {
	state: State = {
		layoutData: [],
		answers: [],
		genericPuzzle: new GenericPuzzle({
			currQuestionNum: this.props.currQuestionNum,
			currQuestionSpec: this.props.currQuestionSpec,
			questionTotal: this.props.questionTotal,
			nextQuestion: this.props.nextQuestion,
		}),
	};

	async componentDidMount() {
		this.updateGenericPuzzle();
	}

	async componentDidUpdate() {
		let { genericPuzzle } = this.state;
		if (genericPuzzle.props.currQuestionNum != this.props.currQuestionNum) {
			this.updateGenericPuzzle();
		}
	}

	async updateGenericPuzzle() {
		const maxClicks = Number(
			await SettingsManager.getSetting(
				'MaxClicksPerPicture',
				await UserManager.getAuthenticatedUserId()
			)
		);
		this.setState({
			genericPuzzle: new GenericPuzzle({
				maxClicks: maxClicks,
				currQuestionNum: this.props.currQuestionNum,
				currQuestionSpec: this.props.currQuestionSpec,
				questionTotal: this.props.questionTotal,
				nextQuestion: this.props.nextQuestion,
			}),
		});
	}

	addData = (type: string, value: string, customFields: string) => {
		this.state.layoutData.push([type, value, customFields]);
	};

	render() {
		let { answers, layoutData, genericPuzzle } = this.state;
		return (
			<>
				{
					// Map all JSON data into layoutData, then call recursive function to build page
					Object.entries(this.props.currQuestionSpec.elements)
						.map(([k, v]) => v)
						.map((ld, v) => {
							this.addData(
								ld['type'],
								ld['value'],
								ld['customFields'] ? JSON.stringify(ld['customFields']) : '[]'
							);
						}) && genericPuzzle.buildPage(layoutData, answers, this.props.nextQuestion, this)
				}
			</>
		);
	}
}
